import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="Custom Guitar Builder"
      heroSubTitle="Serving Atlanta, GA And Beyond"
      heroBackgroundImage="/images/custom-guitar-builder.jpg"
    >
      <SEO
        title="Custom Guitar Builder In Atlanta GA | King Electric Guitars"
        ogDescription="Randy King is a custom guitar builder in Atlanta, GA hand crafting T-Style, LP-Style, and Offset guitars with incredible attention to finish and tone."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Why I Build Custom Guitars</h2>
            <p>
              I'm a guitar maker working out of the small town of <a href="https://goo.gl/maps/hQbdmeN8hDjPm5me9" target="_blank" rel="noreferrer">Griffin</a> just outside of Atlanta, GA. The solid-body electric guitars I make are not intended to be display pieces. They are quality, hand-built instruments that look cool. They're made for working musicians to use every day. They are custom-built to make great music.
            </p>
            <p>
              I want to build your dream guitar. I want you to feel like it's an extension of your body. An electric guitar that is comfortable, great looking, and makes the sound you hear in your head.
            </p>
            <p>
              I'm also a musician. Years of playing live, working in recording studios, and writing music. I know how frustrating it can be to have a sound in your head that you can't reproduce. When you pick up your guitar, you want it to make THAT sound. When you look at it, it should call you to pick it up. And when you play it, it should feel just right.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-builder-luthier.jpg" alt="Custom Guitar Builder In Atlanta GA" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>King Electric Guitars - Models</h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3><a href="/lp-style-guitar-futura/">Futura: LP-Style Guitar</a></h3>
            <p>The <a href="/lp-style-guitar-futura/">Futura</a> is an LP-style guitar made of Spanish cedar, hybrid set-neck bolt on construction with a variety of customization options.</p>
            <p>Starting at <span className="green bold">$2500</span></p>
            <a href="/lp-style-guitar-futura/"><img src="/images/futura-randy.jpg" alt="Custom Guitar Builder LP-Style Guitar Futura" /></a>
          </div>
        }
        textRight={
          <div>
            <h3><a href="/t-style-guitar-taurus/">Taurus: T-Style Guitar</a></h3>
            <p>The <a href="/t-style-guitar-taurus/">Taurus</a> is a T-style guitar with a wide variety of customization options and finishes.</p>
            <p>Starting at <span className="green bold">$2100</span></p>
            <a href="/t-style-guitar-taurus/"><img src="/images/taurus-randy.jpg" alt="Custom Guitar Builder T-Style Guitar Taurus" /></a>
          </div>
        }
      />
      <div className="section textured" id="process">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/randy-process.jpg"
                alt="Custom Guitar Builder in Griffin Georgia, Randy King"
              />
              <Blockquote>
                I build every guitar as a unique instrument made especially for my customer. I don't want everything I make to be the same. Every guitar player needs something different of their instrument. It's important to me to deliver what they want. Sometimes they don't know for sure, and then it's my job to guide them through the process.
              </Blockquote>
            </div>
          }
          textRight={
            <div>
              <h2>My Process As A Custom Guitar Builder</h2>
              <p>
                I like working with people that are in the Atlanta, GA area. You can come in and feel different neck profiles, feel body shapes and contours. You can select wood with me. But, if you can't come here, building online using video conferencing tools is possible as well. It's a little less tactile but possible.
              </p>
              <p>
                <strong>Step one:</strong> we talk. What kind of guitar do you currently play? What do you like and dislike? What are you looking for that you can't walk into a store and pick up? What's that special thing that you need a custom-built guitar to do? Is it a look, is it a feeling, is it a sound?
              </p>
              <p>
                <strong>Step two:</strong> we'll go over the design. Which of my body shapes do you like? Or do you have something else in mind? What type of woods do you like. What do you want weight-wise? What kind of neck are you looking for? What pickup configuration. What bridge?
              </p>
              <p>
                <strong>Step three:</strong> we'll go pick out wood. You can come to the wood shop that I use here in town. Or we can look at my other sources online. Or we may look around my shop here and choose something that I already have.
              </p>
              <p>
                <strong>Step four:</strong> I get the body cut out and shaped. I'll cut out and route the neck, install a dual-action truss rod, and install carbon-fiber reinforcements. Then I install and shape the fretboard and back of the neck. All through this process, I share photos and get your feedback.
              </p>
              <p>
                <strong>Step five:</strong> the finishing process. This part often takes the longest. Finishes need many coats, sanding, and curing time. All the detailing takes time to get right. Again, I share photos throughout the process. 
              </p>
              <p>
                <strong>Step six:</strong> doing your guitar's setup and making any adjustments you need. If you are local to Atlanta, I'll set up the guitar with you in the shop so that I can get it exactly how you want it.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Custom Guitar Build Pricing, Payment, and Turnaround</h2>
            <p>
              Each guitar I build is unique in many ways. So, the price will vary based on the body style, neck, finish, bridge, pickups and electronics you choose. 
            </p>
            <p>
              My <a href="/t-style-guitar-taurus/">Taurus</a> (T-Style) starts at <span className="green bold">$2100</span>, <a href="/lp-style-guitar-futura/">Futura</a> (LP-Style) starts at  <span className="green bold">$2500</span>, and the Space Dolphin (offset style) will start at <span className="green bold">$2300</span>. I can configure and finish each a ton of different ways. I've designed each to be a solid platform that gives you limitless possibilities. We'll talk about all the options and I'll let you know how those choices impact the cost before we get started.
            </p>
            <p>
              I will do completely custom body designs. Due to the extra time it takes to design and create templates, the starting price on a completely custom design is currently $4000.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-builder-headstock.jpg" alt="Custom Guitar Builder In Atlanta GA" />
            <ul>
              <li>$100 down to get added to the waiting list. This fee is nonrefundable, but I will deduct this fee from the price of your guitar upon completion.</li>
              <li>50% down at the start of your build. I will provide a quote based on our initial consultation.</li>
              <li>50% upon completion of your guitar; plus any extra upgrades you've authorized during the build process; then minus your waitlist fee.</li>
              <li>Waitlist turnaround is currently about 4 months depending on the complexity of your build.</li>
            </ul>
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-builder-body-designs.jpg" alt="Custom Electric Guitar Builder In Atlanta GA" />
            </div>
          }
          textRight={
            <div>
              <h2><a href="/guitar-body/">Guitar Body Designs</a></h2>
              <p>
                We'll talk about the solid body guitar shapes you like. I have my own T-Style (Taurus), LP-Style (Futura), and Offset-style (Space Dolphin) templates we can use. Or, if you have something specific in mind, it might be something I can do. We'll talk about the body woods that you want for your custom build.
              </p>
             <p>              
              <LearnMoreButton
                url="/guitar-body/"
              />
             </p>
              <Blockquote>
                I don't buy bodies. I'm building everything from scratch from rough wood I source from local lumber yards. All made by hand in my shop in Griffin, GA. I'm not cranking out body after body with a CNC machine. Every guitar is unique and made one at a time to your specifications.
              </Blockquote>
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2><a href="/custom-guitar-necks">Custom Guitar Necks</a></h2>
            <p>
              The neck is the point where you interact with the guitar. So, it's critical that it feels right when you pick it up. Playing should be effortless as you move up and down the neck. We'll discuss hardwood selection for the neck and fingerboard. We'll also talk about your preferred fretboard radius and neck contouring. And even what kind of fretboard inlays you like.
            </p>
            <p>              
            <LearnMoreButton
              url="/custom-guitar-necks"
            />
            </p>
            <Blockquote>
              When building a neck, I'll make a two-piece or three-piece neck. They are stronger than a one-piece neck. I make my necks out of a vertical grained wood. So I'll usually cut a piece into half or thirds, flip it, and sideways glue them all together. I use dual-action truss rods. That way, if anything ever happens to the neck 50 years from now it will be adjustable. You can make the neck have a back bow or an up bow. It's designed to do both. It's only a minor increase in expense for a lot more functionality and will give the guitar a very long life.
            </Blockquote>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-builder-necks.jpg" alt="Custom Guitar Necks In Atlanta GA" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-builder-bridges.jpg" alt="Custom Guitar Bridges In Atlanta GA" />
            </div>
          }
          textRight={
            <div>
              <h2><a href="/guitar-bridges/">Guitar Bridges</a></h2>
              <p>
                Do you prefer a hardtail bridge or a vibrato system? My guitar body designs have the ability to accommodate most every bridge made. I can also adjust bridge placement to achieve whatever range of tunings you use with perfect intonation. That's one of the many things that sets apart a custom build from any guitar off the guitar store wall.
              </p>
               <p>              
                <LearnMoreButton
                  url="/guitar-bridges/"
                />
               </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2><a href="/guitar-finishing/">Guitar Finishing</a></h2>
            <p>
              This is one of my favorite parts. I come from the custom car world, so I love doing specialized custom finishes. I want to give you something you'll never find anywhere else. Something that looks uniquely yours. We have a lot of options. Fabric tops guitar finishes, unique paint options, and natural wood finishes. I can do custom binding - and even moderate relic treatments if that's your thing.
            </p>
           <p>              
              <LearnMoreButton
                url="/guitar-finishing/"
              />
           </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-builder-finish.jpg" alt="Custom Guitar Finishing In Atlanta GA" />
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-builder-pickups.jpg" alt="Custom Guitar Pickups In Atlanta GA" />
            </div>
          }
          textRight={
            <div>
              <h2><a href="/guitar-pickups/">Guitar Pickups</a></h2>
              <p>
                My stock guitars use Bootstrap and Gatekeeper pickups. These come in a wide variety of options. But, we can use any pickup manufacturer you desire in any combination. Mix up single coils and humbuckers. Let's talk about what kind of sound you are after.
              </p>
              <p>              
                <LearnMoreButton
                  url="/guitar-pickups/"
                />
              </p>
              <Blockquote>
                A lot of people really like to buy a guitar and put the specific pickups that they want in. If you know exactly what pickups you want, then we're gonna put those in. But if you don't know, I have stock pickups that sound fantastic.
              </Blockquote>
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2><a href="/guitar-electronics/">Guitar Electronics</a></h2>
            <p>
              Another place I can make an impact on your tone is with the guitar electronics. I'm not content to use the same thing every other manufacturer uses. Sometimes you have to experiment. Try out different pot values, capacitors, and customized switching options. These are all things we can adjust to get you exactly what you need from your guitar's pickups.
            </p>
            <p>              
              <LearnMoreButton
                url="/guitar-electronics/"
              />
            </p>
            <Blockquote>
              I have a breadboard set up where we can try out different value pots and tone caps outside of the guitar. What sounds right to me is not gonna be the same as what sounds right to you. So you can tell me what your ears tell you is right.
            </Blockquote>
          
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-builder-electronics.jpg" alt="Custom Guitar Electronics In Atlanta GA" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-builder-setup.jpg" alt="Custom Guitar Setup In Atlanta GA" />
            </div>
          }
          textRight={
            <div>
              <h2><a href="/guitar-setup/">Guitar Setup</a></h2>
              <p>
                I individualize your new guitar's setup to you like the rest of the design. We'll talk about the tunings you use, the string gauge you prefer, the frets you like, and what kind of string action works best for you.
              </p>
              <p>              
                <LearnMoreButton
                  url="/guitar-setup/"
                />
              </p>
              <Blockquote>
                In my years of doing repairs, I learned that I can make a $100 guitar play like butter, and I can make a $10,000 guitar play like crap. It all depends in the setup. Having you here while I'm doing a setup, if possible, is a really important thing.
              </Blockquote>
            </div>
          }
        />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
