import React, { } from "react"
import { Button } from "semantic-ui-react"

export default function LearnMoreButton({ url }) {
  return (
    <Button
      as="a"
      href={url}
      className=""
      content="Learn More"
      size="tiny"
      icon="file text"
    />
  )
}
